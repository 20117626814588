import BaseBean from "@/utils/BaseBean";

export interface IChooseAddressDataObj {
    utilInst:ChooseAddressUtil
    coordinate:Array<any>
    searchContent:string
    mapInst:any
    AMap:any
    geocoder:any
    searchAutoComplete:any
}
export default class ChooseAddressUtil extends BaseBean{
    public dataObj:IChooseAddressDataObj

    constructor(proxy:any,dataObj:IChooseAddressDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }

    //为当前组件初始化一些信息
    public async initMap():Promise<void>{
        let mapResult=await this.proxy.utils.map.buildMapInst({container:'chooseAddress'});

        this.dataObj.AMap=mapResult.AMap;
        this.dataObj.mapInst=mapResult.mapInst;
        this.dataObj.searchAutoComplete=mapResult.searchAutoComplete;
        this.dataObj.geocoder=mapResult.geocoder;
        //地图点击时，获取点击地经纬度
        this.dataObj.mapInst.on('click', (e:any)=>  {
            this.mapClick(e);
        })
    }

    //地图点击处理，根据点击的坐标获取省市和完整地址
    public mapClick(e:any):void{
        this.proxy.utils.map.getAddressByLngLat({lngLat:[e.lnglat.lng,e.lnglat.lat],geocoder:this.dataObj.geocoder,fn:(options:any)=>{
                this.dataObj.coordinate=[e.lnglat.lng,e.lnglat.lat];
                this.dataObj.searchContent=options.address;
            }});
    }

    //处理搜索
    public doSearch():void{
        if(this.dataObj.searchContent){
            const loading = this.proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            // 根据关键字进行搜索
            this.dataObj.searchAutoComplete.search(this.dataObj.searchContent, (status:any, result:any)=>{
                // 搜索成功时，result即是对应的匹配数据
                // console.log(result);
                let newCenter=[] as Array<any>;let flag:boolean=true;let point0Name:string='';
                let map:Map<any,any>=new Map();//把经度维度放到map中
                if(result && result.tips){
                    let tips=result.tips;
                    let len=tips.length;
                    if(len>5)len=5;//只取搜索的5个点
                    for(let i=0;i<len;i++){
                        if(flag && tips[i] && tips[i]['location']){
                            newCenter[0]=tips[i]['location']['lng'];
                            newCenter[1]=tips[i]['location']['lat'];
                            point0Name=tips[i].name;
                            flag=false;
                        }
                        map.set(tips[i]['location']['lng'],tips[i]['location']['lat']);
                    }
                }
                //处理搜索结果，把这些坐标点标记到地图上，并且把搜索到的第一个结果作为中心点
                if(newCenter.length>0){
                    this.proxy.utils.map.buildMarker({lng:newCenter[0],lat:newCenter[1],title:point0Name,AMap:this.dataObj.AMap,mapInst:this.dataObj.mapInst});//把第一个点标记在地图上
                    this.dataObj.mapInst.setCenter(newCenter,true,500);
                    this.dataObj.coordinate=[newCenter[0],newCenter[1]];
                }
                loading.close();
            })
        }
    }
}
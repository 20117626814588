import {ref,reactive,toRefs,defineComponent,onBeforeMount,onMounted,onActivated,onDeactivated,nextTick,getCurrentInstance} from 'vue';
import ChooseAddressUtil,{IChooseAddressDataObj} from "@/views/sysviews/map/chooseAddress/chooseAddressUtil";
export default defineComponent ({
    name: "ChooseAddress",
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        let dataObj:IChooseAddressDataObj=reactive<IChooseAddressDataObj>({
            utilInst: {} as any,
            //外界调用该组件可以拿到下方的coordinate、searchContent
            coordinate:[],//点击的坐标点或者搜索结果的第一个坐标点
            searchContent:'',//搜索关键字，也是点击或者搜索结果第一条记录的文字地址
            mapInst:'',//地图实例
            AMap:'',//地图实例
            geocoder:'',//坐标解析实例
            searchAutoComplete:''//搜索实例
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new ChooseAddressUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.initMap();
            })
        })
        //根据内容搜索,把所搜到的第一个坐标点标记在地图上
        const searchHandler=()=>{
            dataObj.utilInst.doSearch();
        }
        return{
            ...toRefs(dataObj),searchHandler
        }
    }
});